/* global $ */

/**
 * Bind events for toggler elements
 */
function bindEvents() {
  const $toggle = $('[data-toggle]');

  // bind load events
  $.each($toggle, (index, element) => {
    if ($(element).is('[type="checkbox"]') && $(element).is(':checked')) {
      $(element)
        .click()
        .prop('checked', true)
        .addClass('is-toggled');
    }
  });

  // bind click events
  $toggle.on('click', (event) => {
    const $button = $(event.delegateTarget);
    const $target = document.getElementById($button.data('toggle'));

    // add classname to toggler element
    $button.toggleClass('is-toggled');
    $button.find('[data-toggle-text]').toggleClass('is-hidden');

    // set focus on [data-toggle-focus]
    setTimeout(() => {
      if ($button.is('.is-toggled')) {
        $($target).find('[data-toggle-focus]:first').focus();
      }
    }, 100);
  });
}

/**
 * Exports
 */
export { bindEvents };
