
import { debug } from 'ShopUiBundle/modules/app/logger';

export function hasObjectFitSupport() {
    return ('objectFit' in document.body.style);
}

export function applyObjectFitIfNeeded(selector) {
    if (!hasObjectFitSupport()) {
        import('object-fit-images').then(({ default: objectFit }) => {
            debug('applying polyfill object-fit-images');
            objectFit(
                Array.from(document.querySelectorAll(selector)),
                { watchMQ: true }
            );
        });
    }

    const isPreviousEdge = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
    if ((!hasObjectFitSupport()) || isPreviousEdge) {
        import('object-fit-videos').then(({ default: objectFitVideos }) => {
            debug('applying polyfill object-fit-videos');
            objectFitVideos();
        });
    }

}
