/**
 * Import modules
 */

// /**
//  * Vendor module import
//  */

import * as anchors from './components/anchors';
import * as autosubmit from './components/autosubmit';
import * as dropdown from './components/dropdown';
import * as offcanvas from './components/offcanvas';
import * as toggler from './components/toggler';
import * as validate from './components/validate';
import * as exclude from './components/exclude';
import * as showtitle from './components/showtitle';
import * as sortselect from './components/sortselect';
import * as accordion from './modules/accordion';
import * as cart from './modules/cart';

dropdown.bindEventsWithoutFoundation();
toggler.bindEvents();
offcanvas.bindEvents();
accordion.bindEvents();
anchors.bindEvents();
autosubmit.bindEvents();
cart.bindEvents();
validate.bindEvents();
exclude.bindEvents();
showtitle.bindEvents();
sortselect.bindEvents();
$(document).foundation();
