/* global $, foundation, Foundation */

/**
 * Bind events for accordions
 */
function bindEvents() {
  const $accordion = $('.accordion');

  // change input value when accordion is toggled
  $accordion.on('down.zf.accordion', (event) => {
    const $checkboxId = $(event.delegateTarget).data('toggle-checkbox');
    if ($checkboxId && document.getElementById($checkboxId)) {
      document.getElementById($checkboxId).value = 1;
    }
  });

  $accordion.on('up.zf.accordion', (event) => {
    const $this = $(event.delegateTarget);
    const $checkboxId = $this.data('toggle-checkbox');
    if ($checkboxId && document.getElementById($checkboxId)) {
      document.getElementById($checkboxId).value = 0;
    }

    // scroll active item into view (on mobile devices, if multi-expand !== true)
    if (
      typeof $this.data('multi-expand') === 'undefined' &&
      Foundation.MediaQuery.atLeast('large') === false
    ) {
      if (!window._rbLegacyAnimateOff) {
        setTimeout(() => {
          if ($this.find('[aria-expanded="true"]').length) {
            $('html,body').animate({
              scrollTop: $this.find('[aria-expanded="true"]').offset().top,
            });
          }
        }, 50);
      }
    }
  });

  // toggle accordion with associated checkbox on page load
  $.each($accordion, (index, object) => {
    if (typeof $(object).data('toggle-checkbox') !== 'undefined' &&
      parseInt(document.getElementById($(object).data('toggle-checkbox')).value, 10) === 1) {
      $(object).foundation('down', $(object).find('.accordion-content'));
    }
  });

  //  only for Accordions they used for facetfilter
  const $accordionFilter = $('.accordion__filter');

  $accordionFilter.on('down.zf.accordion', (event) => {
    const thisAccordion = $(event.target).attr('data-accordion');
    const $otherAccordions = $(`.accordion__filter:not([data-accordion="${thisAccordion}"])`);

    if($otherAccordions.length != 0) {
      $otherAccordions.foundation('up', $otherAccordions.find('.accordion-content'));
    }

    // reflow foundation sliders
    $('[data-slider]').foundation('_reflow');
  });
}
/**
 * Exports
 */
export { bindEvents };
