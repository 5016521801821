/* global $ */

/**
 * Push added/removed items to dataLayer
 */
function pushChangedItem() {
    $('input[name="quantity"]').on('input', function(event) {
        const prevQuantity = $(this).attr('value');
        const newQuantity = $(this).val();
        const quantity = Math.abs(newQuantity - prevQuantity);
        let frame = JSON.parse($(this).attr('data-datalayer'));

        const price = frame.ecommerce.changeType.products[0].price;

        if (newQuantity > prevQuantity) {
            frame.event = 'addToCart';
            frame.ecommerce.add = frame.ecommerce.changeType;
            delete frame.ecommerce.changeType;

            frame.ecommerce.add.products[0].quantity = quantity;
            frame.ecommerce.add.products[0].metric1 = quantity * price;

            window.dataLayer.push(frame);
        } else if (newQuantity < prevQuantity) {
            frame.event = 'removeFromCart';
            frame.ecommerce.remove = frame.ecommerce.changeType;
            delete frame.ecommerce.changeType;

            frame.ecommerce.remove.products[0].quantity = quantity;
            frame.ecommerce.remove.products[0].metric1 = '-' + quantity * price;

            window.dataLayer.push(frame);
        }
    });
}

/**
 * Bind events for cart
 */
function bindEvents() {
    pushChangedItem();
}

/**
 * Exports
 */
export { bindEvents };
