/* global $ */

/**
 * Bind events for mouse over on variantthumbnails
 */

function bindEvents() {
  const $titleContainer = $('*[data-showtitlecontainer]');

  $titleContainer.map((i, element) => {
    const $titleElement = $(element);
    const $titleHolder = $titleElement.find('*[data-showtitle]');
    const $titleText = $titleElement.find('*[data-showtitletext]');

    let hoverTimeout;

    $titleHolder.on('mouseover', (event) => {
      const $element = $(event.target);
      const title = $element.data('showtitle');
      $titleText.html(title);
      clearTimeout(hoverTimeout);
    });

    $titleHolder.on('mouseout', () => {
      hoverTimeout = setTimeout(() => {
        $titleText.html('');
      }, 200);
    });

    return (true);
  });
}

/**
 * Exports
 */
export { bindEvents };
