import { log } from '../app/logger';

export function registerServiceWorker(): void {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((registration: ServiceWorkerRegistration) => {
                    log('SW registered: ', registration);
                }).catch((registrationError: Error) => {
                    log('SW registration failed: ', registrationError);
                });
        });
    }
}
