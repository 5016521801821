/* global $ */

/**
 * Bind events
 */
function bindEvents() {
  const $selects = $('[data-sortselect]');

  $.each($selects, (index, element) => {
    const $select = $(element);
    const $options = $select.find('option:gt(1)');
    const $firstOptions = $select.find('option:lt(2)');
    const selected = $select.val();

    $options.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    $select
      .empty()
      .append($firstOptions)
      .append($options);
    $select.val(selected);
  });
}

/**
 * Exports
 */
export { bindEvents };
