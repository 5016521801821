/* global $ */

/**
 * Bind event to deactivate other Inputs when checked
 */
function bindEvents() {
  const $containerForm = $('[data-exclude-form]');
  const $radios = $containerForm.find('input[data-exclude]:not([disabled])');
  const $targets = $containerForm.find('[data-exclude-target]');

  $radios.on('change', () => {
    const $element = $containerForm.find('input[data-exclude]:checked');

    // reset all targets
    $targets
      .prop('disabled', false)
      .next('label')
      .find('[data-exclude-message]')
      .remove();

    // the ID of the Element that shout be disabled
    const targetIds = $element.data('exclude');

    // if there is a ID
    if (targetIds && targetIds !== '') {
      let targetIdsArray;
      if (targetIds.toString().indexOf(',') !== -1) {
        targetIdsArray = targetIds.split(',');
      } else {
        targetIdsArray = [targetIds];
      }

      [].forEach.call(targetIdsArray, (targetId) => {
        const $targetInput = $(`[data-exclude-target][data-value="${targetId}"]`);
        if ($targetInput) {
          if (!$targetInput.prop('disabled')) {
            const excludeMessage = $targetInput.data('exclude-message');
            $targetInput.prop('disabled', true);
            $targetInput.next('label').find('.label')
              .append($(`<span class="help-block" data-exclude-message>${excludeMessage}</span>`));

            // select next or previous radio button
            if ($targetInput.is(':checked')) {
              const $nextInput = $targetInput.nextAll('input:not(:disabled)');
              const $prevInput = $targetInput.prevAll('input:not(:disabled)');
              if ($nextInput.length) {
                $nextInput.first().prop('checked', true);
              } else if ($prevInput.length) {
                $prevInput.last().prop('checked', true);
              }
              $targetInput.prop('checked', false);
              $('[data-exclude-warning]').removeClass('is-hidden').show();
            }
          }
        }
      });
    }
  });

  if ($containerForm.find('input[data-exclude]:checked').length) {
    $containerForm.find('input[data-exclude]:checked').trigger('change');
  } else {
    $radios.first().trigger('change').click();
  }
}

/**
 * Exports
 */
export { bindEvents };
