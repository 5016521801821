import 'string-replace-loader?{ multiple: [ { search: "require([", replace: "void([" }, { search: "if (true)", replace: "if(false)" } ]  }!jquery-form-validator/form-validator/jquery.form-validator';

// Force not loading recaptcha
$.formUtils.hasLoadedGrecaptcha = true;

import 'jquery-form-validator/form-validator/security';

/**
 * Bind events for elements to validate them
 */

function bindEvents() {

  $.formUtils.addValidator({
    name : 'rpassword',
    validatorFunction : function(value) {

      const VALID_LETTERS_SMALL = new RegExp('[a-züöä]');
      const VALID_LETTERS_UPPER = new RegExp('[A-ZÜÖÄ]');
      const VALID_NUMBERS       = new RegExp('[0-9]');
      const VALID_CHARS         = new RegExp('[\-#\*!"§$€%&=,;:@^°<>ß_\/\(\)\\\.\+\?]');

        if( value.length < 6 ) {
            return false;
        }

        if( VALID_LETTERS_SMALL.test(value) === false) {
            return false;
        }

        if( VALID_LETTERS_UPPER.test(value) === false) {
            return false;
        }

        if( VALID_NUMBERS.test(value) === false && VALID_CHARS.test(value) === false) {
            return false;
        }

        // everything is fine
        return true;

        },
    errorMessage : 'Das Passwort sollte aus mindestens 6 Zeichen bestehen, Groß- und Kleinbuchstaben enthalten, sowie mindestens eine Zahl oder ein Sonderzeichen.',
    errorMessageKey: 'badPassword'
});

  /* init the http://www.formvalidator.net/ plugin */
  $.validate({
    borderColorOnError: '',
    scrollToTopOnError: false,
    //lang: document.querySelector('html').getAttribute('lang') || 'en',
    onError: ($form) => {
      /* scroll the first error into the view */
      const $firstErrorPosition = $form.find('input.error').first().offset();
      let $scrollParent = $('html, body');

      if ($form.parents('.reveal').length) {
        $scrollParent = $form.parents('.reveal');
      }

      if ($firstErrorPosition) {
        if (!window._rbLegacyAnimateOff) {
          $scrollParent.animate({
            scrollTop: $firstErrorPosition.top - 200,
          });
        }
      }
    },
  });

  const $passwordInput = $('input[data-pw-strength]');
  const $passwordContainer = $passwordInput.parents('label');

  /* strengh meter */
  const pwstrengthText = {
    bad: $passwordInput.data('pwstrength-bad'),
    weak: $passwordInput.data('pwstrength-weak'),
    good: $passwordInput.data('pwstrength-good'),
    strong: $passwordInput.data('pwstrength-strong'),
  };

  const $passwordSuccess = $('<span>')
    .html($passwordInput.data('validation-success-msg'))
    .addClass('help-block form-success')
    .appendTo($passwordContainer);

  const $passwordStrength = $('<span>')
        .addClass('strength-meter')
        .appendTo($passwordContainer);

  function checkPasswordStrength() {
      const $strengthMeter = $passwordContainer.find('.strength-meter');

      const VALID_LETTERS_SMALL = new RegExp('[a-züöä]');
      const VALID_LETTERS_UPPER = new RegExp('[A-ZÜÖÄ]');
      const VALID_NUMBERS       = new RegExp('[0-9]');
      const VALID_CHARS         = new RegExp('[\-#\*!"§$€%&=,;:@^°<>ß_\/\(\)\\\.\+\?]');
      let complexity = 0;
      const value = $passwordInput.val();

      if( value.length >= 6 ) { complexity += 4; }
      if( value.length >= 8 ) { complexity += 7; }
      if( VALID_LETTERS_SMALL.test(value) === true) { complexity += 1; }
      if( VALID_LETTERS_UPPER.test(value) === true) { complexity += 1; }
      if( VALID_NUMBERS.test(value) === true) { complexity += 0.5; }
      if( VALID_CHARS.test(value) === true) { complexity += 0.5; }

      if(complexity < 5 ) {
          $strengthMeter.html(pwstrengthText.bad).attr('class','').addClass('strength-meter strength-meter--bad');
          $passwordContainer.removeClass('has-success');
          return;
      }

      if(complexity <= 6 ) {
          $strengthMeter.html(pwstrengthText.weak).attr('class','').addClass('strength-meter strength-meter--weak');
          $passwordContainer.removeClass('has-success');
          return;
      }

      if(complexity < 7 ) {
          $strengthMeter.html(pwstrengthText.good).attr('class','').addClass('strength-meter strength-meter--good');
          $passwordContainer.addClass('has-success');
          return;
      }

      if(complexity >= 13.5 ) {
          $strengthMeter.html(pwstrengthText.strong).attr('class','').addClass('strength-meter strength-meter--strong');
          $passwordContainer.addClass('has-success');
          return;
      }

  }

  $passwordInput.on('keyup', checkPasswordStrength);

  /* custom validator for our very special salutation Element */
  const $form = $('form');
  /* we don't listen on submit, because the valdiate plugin also work with this listener */
  const $submitButton = $form.find('button[type="submit"]');

  function checkSalutation() {
    const $salutationInputs = $form.find('.gender-choice input[type="radio"]');
    $salutationInputs.on('change', checkSalutation);

    if ($salutationInputs.is(':checked')) {
      $salutationInputs.parents('fieldset').find('.form-error').addClass('is-hidden');
    } else {
      $salutationInputs.parents('fieldset').find('.form-error').removeClass('is-hidden');
    }
  }

  $submitButton.on('click', checkSalutation);
}

/**
 * Exports
 */
export { bindEvents };
