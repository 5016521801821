
import { executeCallbackWhenServiceIsAccepted } from 'ShopUiBundle/modules/usercentrics';

function loadTrboScript(): void {
    const trboId = document.documentElement.dataset.trboId;

    if (!trboId) {
        return;
    }

    if (document.readyState == 'complete') {
        injectTrboScript();
    } else {
        window.addEventListener('load', () => injectTrboScript());
    }
}

function injectTrboScript(): void {
    const trboId = document.documentElement.dataset.trboId;

    if (!trboId) {
        return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = `//static.trbo.com/plugin/${trboId}.js`;
    document.head.insertAdjacentElement(
        'beforeend',
        script,
    );
}

export function loadTrbo(): void {
    executeCallbackWhenServiceIsAccepted('trbo', loadTrboScript);
}
