import 'custom-event-polyfill';
import 'whatwg-fetch';
import 'core-js/features/array/from';
import 'core-js/features/promise';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/map/map-values';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/includes';
import 'core-js/features/array/includes';
import 'core-js/features/array/for-each';
import 'core-js/features/array/find';
import 'core-js/features/array/flat-map';
import 'core-js/features/url-search-params';
import '@webcomponents/webcomponentsjs/bundles/webcomponents-ce';
import closestPolyfill from 'element-closest';
import 'abortcontroller-polyfill';
import { debug } from 'ShopUiBundle/modules/app/logger';
import { applyButtonFocusIfNeeded } from 'ShopUiBundle/modules/macos-button-focus';
import { applyObjectFitIfNeeded } from 'ShopUiBundle/modules/object-fit';
import EventTargetPolyfill from '@mattkrick/event-target-polyfill';
import { applyDefault as applyStickyDefaultIfNeeded } from 'ShopUiBundle/modules/sticky';
import { isMSIE } from '../browser';

closestPolyfill(window);

// check if the browser natively supports webcomponents
const hasNativeCustomElements = !!window.customElements;
// https://github.com/webcomponents/webcomponentsjs#custom-elements-es5-adapterjs
if (hasNativeCustomElements) {
  // tslint:disable-next-line
  import(/* webpackMode: "eager" */'@webcomponents/webcomponentsjs/custom-elements-es5-adapter');
}

if (!window.HTMLPictureElement) {
  import('picturefill').then(() => {
    debug('polyfill picturefill loaded');
  });
}

if (!('remove' in Element.prototype)) {
  Element.prototype['remove'] = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

applyButtonFocusIfNeeded();
applyObjectFitIfNeeded('.basic-picture__img');

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

try {
  const eventTarget = new EventTarget();
} catch(e) {
  window.EventTarget = EventTargetPolyfill;
}

if (window.document.body.style.scrollBehavior == undefined) {
  import('smoothscroll-polyfill').then((smoothscroll) => {
    smoothscroll.polyfill();
    debug('polyfill smoothscroll loaded');
  });

}

if (isMSIE()) {
  import('svgxuse/svgxuse');

  import('focus-within').then((focusWithin) => {
    focusWithin.default(document);
  });
}

applyStickyDefaultIfNeeded();
