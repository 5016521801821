
import { debug } from 'ShopUiBundle/modules/app/logger';
import { isSafari, isFirefox, isMacos } from '../browser';

export function hasButtonFocusSupport(): boolean {
    return !(isMacos() && (isSafari() || isFirefox()));
}

export function applyButtonFocusIfNeeded(): void {
    if (!hasButtonFocusSupport()) {
        debug('applying polyfill macos-button-focus');

        document.body.addEventListener('click', (e) => {
            const targetElements = [
                'button',
            ].join(',');

            if (e?.target instanceof HTMLElement) {
                const closestTarget = e.target.closest(targetElements);

                if (closestTarget instanceof HTMLElement) {
                    (closestTarget as HTMLElement).focus();
                }
            }
        });
    }
}
