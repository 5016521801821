import register from 'ShopUiBundle/modules/app/registry';
import 'ShopUiBundle/components/molecules/main-navigation-category';
import 'ShopUiBundle/components/molecules/main-navigation-category-with-media';
import 'ShopUiBundle/components/molecules/main-navigation-category-with-tiles';
import 'ShopUiBundle/components/molecules/main-navigation-category-bikes';
import 'ShopUiBundle/components/molecules/main-navigation-category-bikes-level-2';
import 'ShopUiBundle/components/molecules/main-navigation-category-bikes-level-3';
import 'ShopUiBundle/components/molecules/main-navigation-category-bikes-level-4';
import 'ShopUiBundle/components/molecules/main-navigation-cms-category';
import 'ShopUiBundle/components/molecules/main-navigation-item-details-with-image';
import 'ShopUiBundle/components/molecules/main-navigation-item';
import 'ShopUiBundle/components/molecules/main-navigation-item-index';

import './style.scss';

// tslint:disable-next-line
register('main-navigation', () => import(/* webpackMode: "lazy" */'./main-navigation'));
