import { debug } from 'ShopUiBundle/modules/app/logger';
import { hasStickySupport } from 'ShopUiBundle/modules/browser';

export function apply(elements: NodeListOf<HTMLElement>): void {
    if (!hasStickySupport()) {
        import('stickyfilljs').then((stickyfill) => {
            stickyfill.add(Array.from(elements));
            debug('Sticky polyfill applied');
        });
    }
}

export function applyDefault(): void {
    apply(document.querySelectorAll('.fixedsticky'));
}
