/* global $ */

/**
 * Bind event to autosubmit a form by change event from a input/select/radio/checkbox
 */
function bindEvents() {
  let timeout = false;

  $('select[data-autosubmit], ' +
    'input[type="radio"][data-autosubmit], ' +
    'input[type="checkbox"][data-autosubmit]').on('change', (event) => {
      $(event.target).parents('form').submit();
    });


  $('input[type="number"][data-autosubmit]')
    .on('focus', (event) => {
      $(event.target).select();
    })
    .on('keyup', (event) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const field = $(event.delegateTarget);
        if (field.val().match(/^[0-9]+$/)) {
          field
            .addClass('is-loading')
            .parents('form')
            .submit();
        }
      }, 500);
    });
}

/**
 * Exports
 */
export { bindEvents };
