// @ts-nocheck
import { Accordion } from 'foundation-sites/js/foundation.accordion';
import { Foundation } from 'foundation-sites/js/foundation.core';
import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { Slider } from 'foundation-sites/js/foundation.slider';
import { Tabs } from 'foundation-sites/js/foundation.tabs';
import { Toggler } from 'foundation-sites/js/foundation.toggler';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import $ from 'jquery';

import './style.scss';

// TODO: remove
Foundation.addToJquery($);
Foundation.plugin(Reveal, 'Reveal');
Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(OffCanvas, 'OffCanvas');
Foundation.plugin(Toggler, 'Toggler');
Foundation.plugin(MediaQuery, 'MediaQuery');
Foundation.plugin(Tabs, 'Tabs');
Foundation.plugin(Slider, 'Slider');
$(document).foundation();
