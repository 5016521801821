let tmpScrollY = 0;

export function enableScrolling(): void {
    document.body.classList.remove('has--fixed-content');
    document.body.parentElement!.classList.remove('u-vertical-scrollbar');
    document.body.style.top = '';
    window.scrollTo(window.scrollX, tmpScrollY);
}

export function disableScrolling(): void {
    document.body.style.top = `-${window.scrollY}px`;
    tmpScrollY = window.scrollY;
    if (hasVerticalScrollbar()) {
        document.body.parentElement!.classList.add('u-vertical-scrollbar');
    }
    document.body.classList.add('has--fixed-content');
}

export function hasVerticalScrollbar(): boolean {
    return getVerticalScrollbarWidth() > 0;
}

export function getVerticalScrollbarWidth(): number {
    return window.innerWidth - document.body.offsetWidth;
}

export function hasStickySupport(): boolean {
    if (!(window.CSS && CSS.supports)) {
        return false;
    }

    return !![
        'sticky',
        '-webkit--sticky',
        '-ms-sticky',
        '-moz-sticky',
        '-o-sticky',
    ].find(vendor => CSS.supports('position', vendor));
}

export function isMacos(): boolean {
    return (navigator.platform.indexOf('Mac') >= 0);
}

export function isSafari(): boolean {
    // https://stackoverflow.com/a/7768006
    return (navigator.userAgent.indexOf('Safari') != -1
        && navigator.userAgent.indexOf('Chrome') == -1);
}

export function isFirefox(): boolean {
    return !((window as any).mozInnerScreenX == null);
}

export function isMSIE(): boolean {
    return /MSIE|Trident/.test(navigator.userAgent);
}
