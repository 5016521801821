/* global $ */

/**
 * Bind events for offcanvas elements
 */
function bindEvents() {
  $(window).on('opened.zf.offcanvas', () => {
    $('html').addClass('has-offcanvas');
  });

  $(window).on('closed.zf.offcanvas', () => {
    $('html').removeClass('has-offcanvas');
  });
}

/**
 * Exports
 */
export { bindEvents };
