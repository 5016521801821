/* global $ */
function closeOpenDropDowns() {
  $('.toggle-dropdown.is-open').each((index, element) => {
      const $this = $(element);
      const $button =  $this.find('.toggle-dropdown__trigger');

      $this.removeClass('is-open');
  });
}

function bindEventsWithoutFoundation() {
    // simple dropdown without foundation
    const $toggleDropdown = $('.toggle-dropdown:not(.toggle-dropdown--rendered)');

    $toggleDropdown.on('mousedown', event => event.stopPropagation());

    $toggleDropdown.each((index, element) => {
      const $this = $(element);
      const $button = $this.find('.toggle-dropdown__trigger');

      $button.on('click', (event) => {

          // stop every other Events
          event.preventDefault();
          event.stopImmediatePropagation();

          if(!$this.hasClass('is-open')) {

              // close other dropdowns
              closeOpenDropDowns();

              $this.addClass('is-open');

              $this.trigger('dropDownOpen');
          } else {
              // close other dropdowns
              closeOpenDropDowns();
          }
      });
    });

    $toggleDropdown.addClass('toggle-dropdown--rendered');
}

$(document).on('mousedown', () => {
    closeOpenDropDowns();
});

/**
 * Exports
 */
export { bindEventsWithoutFoundation };
