import register from 'ShopUiBundle/modules/app/registry';

import 'ShopUiBundle/components/atoms/slider-pagination';
import 'SprykerCatalogBundle/components/molecules/catalog-category-model';
import 'SprykerCmsBundle/components/molecules/partner-box';
import 'SprykerCmsBundle/components/molecules/product-box';

import './style.scss';

// tslint:disable-next-line
register('catalog-category-models', () => import(/* webpackMode: "lazy" */'./catalog-category-models'));
