import register from 'ShopUiBundle/modules/app/registry';
import 'ShopUiBundle/components/atoms/header-navigation-logo';
import 'ShopUiBundle/components/molecules/header-main-navigation';
import 'ShopUiBundle/components/molecules/header-mobile-menu';
import 'ShopUiBundle/components/molecules/header-navigation-bikeleasing';
import 'ShopUiBundle/components/molecules/header-navigation-language';
import 'ShopUiBundle/components/molecules/header-navigation-magazin';
import 'ShopUiBundle/components/molecules/header-navigation-services';
import 'ShopUiBundle/components/molecules/header-navigation-stores';
import 'ShopUiBundle/components/molecules/header-navigation-user';
import 'ShopUiBundle/components/molecules/header-search-form';
import 'ShopUiBundle/components/molecules/menu-mobile-button';

import './style.scss';

register('shop-header', () => import(/* webpackMode: "lazy" */'./shop-header'));
