declare global {
    type UsercentricsServicesFullInfo = { name: string, id: string }[];
    type UsercentricsOnExecuteCallback = (e?: Event) => void;
    type UsercentricsOnExecuteCallbackRecordItem = {
        id: string,
        callback: UsercentricsOnExecuteCallback,
        checkExplicit: boolean,
    };

    interface UC_UI_Interface {
        isInitialized: () => boolean;
        getServicesFullInfo: () => UsercentricsServicesFullInfo;
        acceptService: (id: string) => void;
        showSecondLayer: (id: string) => void;
        closeCMP: () => Promise<void>;
    }

    interface Window {
        _rbCookieConsentOff: boolean | undefined;
        _rbHelpWidgetButtonOff: boolean | undefined;
        UC_UI: UC_UI_Interface | undefined;
    }
}

export const USERCENTRICS_CALLBACK_RECORD_ITEMS: UsercentricsOnExecuteCallbackRecordItem[] = [];

export enum USERCENTRICS_EVENTS {
    initialized = 'UC_UI_INITIALIZED',
    updated = 'uc_consent_status',
}

export async function getService(
    id: string,
    cachedServicesFullInfo?: UsercentricsServicesFullInfo,
): Promise<any> {
    const isInitialized = window.UC_UI?.isInitialized();

    if (!isInitialized) return null;

    const services = cachedServicesFullInfo ?? (await window.UC_UI?.getServicesFullInfo()) ?? [];
    const service = services.find((item) => item.name === id || item.id === id);

    return service;
}

export async function isServiceAccepted(
    id: string,
    checkExplicit = true,
    cachedServicesFullInfo?: UsercentricsServicesFullInfo,
): Promise<boolean | null> {
    let value: boolean | null = null;

    const service = await getService(id, cachedServicesFullInfo);

    if (service) {
        if (checkExplicit) {
            if (service.consent.history.length) {
                value = service.consent.status && service.consent.history[service.consent.history.length - 1].type === 'explicit';
            }
        } else {
            value = service.consent.status;
        }
    }

    return value;
}

export async function acceptService(id: string): Promise<void> {
    const service = await getService(id);

    if (service) {
        window.UC_UI?.acceptService(service.id);
    }
}

export async function showService(id: string): Promise<void> {
    const service = await getService(id);

    if (service) {
        window.UC_UI?.showSecondLayer(service.id);
    }
}

export async function executeCallbackWhenServiceIsAccepted(
    id: string,
    callback: UsercentricsOnExecuteCallback,
    checkExplicit = true,
): Promise<void> {
    if (window._rbCookieConsentOff || await isServiceAccepted(id)) {
        callback();
    } else {
        window.removeEventListener(USERCENTRICS_EVENTS.initialized, onUsercentricsStatusUpdated);
        window.removeEventListener(USERCENTRICS_EVENTS.updated, onUsercentricsStatusUpdated);

        USERCENTRICS_CALLBACK_RECORD_ITEMS.unshift({
            id: id,
            callback: callback,
            checkExplicit: checkExplicit,
        });

        window.addEventListener(USERCENTRICS_EVENTS.initialized, onUsercentricsStatusUpdated);
        window.addEventListener(USERCENTRICS_EVENTS.updated, onUsercentricsStatusUpdated);
    }
}

export async function onUsercentricsStatusUpdated(e?: Event): Promise<void> {
    const services = await window.UC_UI?.getServicesFullInfo() ?? [];

    for (let i = USERCENTRICS_CALLBACK_RECORD_ITEMS.length - 1; i >= 0; i--) {
        const item = USERCENTRICS_CALLBACK_RECORD_ITEMS[i];

        const consentStatus = await isServiceAccepted(item.id, item.checkExplicit, services);

        if (consentStatus) {
            USERCENTRICS_CALLBACK_RECORD_ITEMS.splice(i, 1);

            item.callback();
        }
    }

    if (!USERCENTRICS_CALLBACK_RECORD_ITEMS.length) {
        window.removeEventListener(USERCENTRICS_EVENTS.initialized, onUsercentricsStatusUpdated);
        window.removeEventListener(USERCENTRICS_EVENTS.updated, onUsercentricsStatusUpdated);
    }
}
