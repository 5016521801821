/* global $ */

/**
 * Scroll anchor links
 */
function bindEvents() {
  $('a[href^=\\#]:not([href=\\#]):not([role="tab"]):not([data-smooth-scroll])').on('click', (event) => {
    const hash = $(event.currentTarget.hash);

    if (!hash.length) {
      event.preventDefault();
      return;
    }

    const eventPos = $(event.currentTarget).offset().top;
    let scrollPos = hash.offset().top;

    // if we scroll upwards, the header will appear
    if(eventPos > scrollPos) {
      const headerHeight = $('.header').height();
      scrollPos = scrollPos - headerHeight - 50;
    }

    if (hash.length) {
      if (!window._rbLegacyAnimateOff) {
        $('html,body').animate({
         scrollTop: scrollPos,
        }, 500);
      }
    }
  });
}

/**
 * Exports
 */
export { bindEvents };
