declare let __webpack_public_path__: string;

// eslint-disable-next-line prefer-const,@typescript-eslint/no-unused-vars
__webpack_public_path__ = (document.getElementById('cdn-host')?.getAttribute('content') ?? '') + '/assets/';

// Vendors
import 'ShopUiBundle/vendors/foundation-sites';

// Styles
import '../../styles/basic';

import 'ShopUiBundle/modules/polyfills';

// Legacy
import '../../assets/js/app';

// Templates/Views/Components
import 'ShopUiBundle/components/atoms/action-panel-group-title';
import 'ShopUiBundle/components/atoms/basic-checkbox';
import 'ShopUiBundle/components/atoms/basic-headline';
import 'ShopUiBundle/components/atoms/basic-radiobutton';
import 'ShopUiBundle/components/atoms/basic-scrollbar';
import 'ShopUiBundle/components/atoms/basic-text';
import 'ShopUiBundle/components/atoms/basic-tooltip';
import 'ShopUiBundle/components/atoms/basic-video';
import 'ShopUiBundle/components/atoms/btn';
import 'ShopUiBundle/components/atoms/btn-star-select';
import 'ShopUiBundle/components/atoms/checkbox-slider';
import 'ShopUiBundle/components/atoms/column-title';
import 'ShopUiBundle/components/atoms/form-check';
import 'ShopUiBundle/components/atoms/form-check-group';
import 'ShopUiBundle/components/atoms/form-check-card-group';
import 'ShopUiBundle/components/atoms/form-input';
import 'ShopUiBundle/components/atoms/form-password';
import 'ShopUiBundle/components/atoms/form-validator';
import 'ShopUiBundle/components/atoms/gtm-data-layer-action';
import 'ShopUiBundle/components/atoms/horizontal-rule';
import 'ShopUiBundle/components/atoms/icon-arrow-animated';
import 'ShopUiBundle/components/atoms/icon';
import 'ShopUiBundle/components/atoms/intro-text';
import 'ShopUiBundle/components/atoms/logo-loader';
import 'ShopUiBundle/components/atoms/panel-btn';
import 'ShopUiBundle/components/atoms/platform-logo';
import 'ShopUiBundle/components/atoms/quantity-input-button';
import 'ShopUiBundle/components/atoms/radio-button-check-icon';
import 'ShopUiBundle/components/atoms/search-box';
import 'ShopUiBundle/components/atoms/tag';
import 'ShopUiBundle/components/atoms/text-link';
import 'ShopUiBundle/components/atoms/toggle-action';
import 'ShopUiBundle/components/atoms/trigger-action';
import 'ShopUiBundle/components/atoms/usercentrics-dialog';
import 'ShopUiBundle/components/atoms/usercentrics-iframe-loader';
import 'ShopUiBundle/components/atoms/usercentrics-script-loader';
import 'ShopUiBundle/components/atoms/usercentrics-supress-cmp-display';
import 'ShopUiBundle/components/molecules/usercentrics-view-loader';
import 'ShopUiBundle/components/molecules/action-message';
import 'ShopUiBundle/components/molecules/address-form-symfony';
import 'ShopUiBundle/components/molecules/action-panel';
import 'ShopUiBundle/components/molecules/action-panel-border';
import 'ShopUiBundle/components/molecules/action-panel-form';
import 'ShopUiBundle/components/molecules/action-panel-link-button';
import 'ShopUiBundle/components/molecules/banner-message';
import 'ShopUiBundle/components/molecules/basic-accordion';
import 'ShopUiBundle/components/molecules/basic-modal';
import 'ShopUiBundle/components/molecules/basic-pagination';
import 'ShopUiBundle/components/molecules/basic-tabs';
import 'ShopUiBundle/components/molecules/basic-teaser';
import 'ShopUiBundle/components/molecules/brand-navigation';
import 'ShopUiBundle/components/molecules/card-slider';
import 'ShopUiBundle/components/molecules/chatbot-help-button';
import 'ShopUiBundle/components/molecules/chatbot-help-widget';
import 'ShopUiBundle/components/molecules/chatbot-help-wrapper';
import 'ShopUiBundle/components/molecules/column-layout';
import 'ShopUiBundle/components/molecules/epoq-slider';
import 'ShopUiBundle/components/molecules/fancy-accordion';
import 'ShopUiBundle/components/molecules/flash-message';
import 'ShopUiBundle/components/molecules/payment-shipment-list';
import 'ShopUiBundle/components/molecules/image-360';
import 'ShopUiBundle/components/molecules/image-or-video';
import 'ShopUiBundle/components/molecules/language-dropdown';
import 'ShopUiBundle/components/molecules/list-certificate-icons';
import 'ShopUiBundle/components/molecules/list-certification-logos';
import 'ShopUiBundle/components/molecules/list-payment-icons';
import 'ShopUiBundle/components/molecules/list-shipment-icons';
import 'ShopUiBundle/components/molecules/modal-dialog';
import 'ShopUiBundle/components/molecules/newsletter-form';
import 'ShopUiBundle/components/molecules/quantity-input';
import 'ShopUiBundle/components/molecules/sidebar-more-button';
import 'ShopUiBundle/components/molecules/selection-button-group';
import 'ShopUiBundle/components/molecules/selection-field';
import 'ShopUiBundle/components/molecules/selection-field-radio';
import 'ShopUiBundle/components/molecules/selection-field-radio-group';
import 'ShopUiBundle/components/molecules/selection-field-radio-small';
import 'ShopUiBundle/components/molecules/seo-text';
import 'ShopUiBundle/components/molecules/text-input';
import 'ShopUiBundle/components/molecules/title-with-content';
import 'ShopUiBundle/components/molecules/tooltip-icon-info';
import 'ShopUiBundle/components/molecules/tooltip-info';
import 'ShopUiBundle/components/molecules/trbo-slider';
import 'ShopUiBundle/components/molecules/video-player';
import 'ShopUiBundle/components/molecules/zoom-images';
import 'ShopUiBundle/components/organisms/accordion-block';
import 'ShopUiBundle/components/organisms/advantage-teaser';
import 'ShopUiBundle/components/organisms/banner-slider';
import 'ShopUiBundle/components/organisms/basic-attention';
import 'ShopUiBundle/components/organisms/basic-keyfeatures';
import 'ShopUiBundle/components/organisms/bike-benefits';
import 'ShopUiBundle/components/organisms/category-short-cut-links';
import 'ShopUiBundle/components/organisms/compare-bikes-table';
import 'ShopUiBundle/components/organisms/content-slider';
import 'ShopUiBundle/components/organisms/footer-newsletter';
import 'ShopUiBundle/components/organisms/fullscreen-video-teaser';
import 'ShopUiBundle/components/organisms/icon-box-slider';
import 'ShopUiBundle/components/organisms/image-button-slider';
import 'ShopUiBundle/components/organisms/image-slider-with-description';
import 'ShopUiBundle/components/organisms/image-with-brand-icon-list';
import 'ShopUiBundle/components/organisms/info-banner';
import 'ShopUiBundle/components/organisms/lifestyle-gallery';
import 'ShopUiBundle/components/organisms/product-newsletter';
import 'ShopUiBundle/components/organisms/shop-footer';
import 'ShopUiBundle/components/organisms/shop-header';
import 'ShopUiBundle/components/organisms/shop-usp';
import 'ShopUiBundle/components/organisms/sticky-buybox';
import 'ShopUiBundle/components/organisms/teaser-layout';
import 'ShopUiBundle/components/organisms/teaser-layout-2';
import 'ShopUiBundle/components/organisms/category-slider';
import 'ShopUiBundle/components/organisms/title-with-content-v2';
import 'SprykerCatalogBundle/components/molecules/catalog-bike-info';
import 'SprykerCatalogBundle/components/molecules/catalog-breadcrumb';
import 'SprykerCatalogBundle/components/molecules/catalog-category-bikes';
import 'SprykerCatalogBundle/components/molecules/catalog-category-logo-footer';
import 'SprykerCatalogBundle/components/molecules/catalog-category-title-with-video';
import 'SprykerCatalogBundle/components/molecules/catalog-faq';
import 'SprykerCatalogBundle/components/molecules/catalog-navigation';
import 'SprykerCatalogBundle/components/molecules/catalog-search-form';
import 'SprykerCatalogBundle/components/molecules/catalog-suggestions';
import 'SprykerCatalogBundle/components/organisms/catalog-categories';
import 'SprykerCatalogBundle/components/organisms/catalog-category-models';
import 'SprykerCatalogBundle/components/organisms/catalog-filter-row';
import 'SprykerCheckoutBundle/components/molecules/info-text';
import 'SprykerCheckoutBundle/components/molecules/info-text-with-icon';
import 'SprykerCmsBundle/components/molecules/stage-slider';
import 'SprykerCmsBundle/components/organisms/banner-with-button';
import 'SprykerCmsBundle/components/organisms/faq-banner';
import 'SprykerCmsBundle/components/organisms/faq-contact';
import 'SprykerCmsBundle/components/organisms/faq-icon-list-with-title';
import 'SprykerCmsBundle/components/organisms/faq-list-with-title';
import 'SprykerCmsBundle/components/organisms/faq-nav-list-with-text';
import 'SprykerCmsBundle/components/organisms/faq-short-overview';
import 'SprykerCustomerBundle/components/atoms/account-key-value-list';
import 'SprykerCustomerBundle/components/atoms/toggle-subform';
import 'SprykerCustomerBundle/components/molecules/account-form-title';
import 'SprykerCustomerBundle/components/molecules/account-info-box';
import 'SprykerCustomerBundle/components/molecules/account-order-footer';
import 'SprykerCustomerBundle/components/molecules/account-order-item';
import 'SprykerCustomerBundle/components/molecules/account-order-list';
import 'SprykerCustomerBundle/components/molecules/account-order-overview-item';
import 'SprykerCustomerBundle/components/molecules/account-page-title';
import 'SprykerCustomerBundle/components/molecules/customer-id';
import 'SprykerCustomerBundle/components/molecules/password-discard';
import 'SprykerCustomerBundle/components/organisms/account-order';
import 'SprykerProductBundle/components/atoms/product-subtitle';
import 'SprykerProductBundle/components/molecules/catalog-product-tile';
import 'SprykerProductBundle/components/molecules/product-main-features';
import 'SprykerProductBundle/components/molecules/products-slider';
import 'SprykerProductBundle/components/organisms/brand-highlights';
import 'SprykerProductBundle/components/organisms/top-products';
import 'SprykerProductReviewBundle/components/molecules/rating-stars';
import 'SprykerShipmentBundle/components/molecules/packstation-list';
import 'SprykerShipmentBundle/components/molecules/packstation-loader';
import 'SprykerShipmentBundle/components/molecules/pickup-store-list';
import 'SprykerShipmentBundle/components/molecules/pickup-store-list-header';
import 'SprykerShipmentBundle/components/molecules/shipment-customer-phone';
import 'SprykerCartBundle/components/molecules/cart-footer';

import { bootstrap } from 'ShopUiBundle/modules/app';
import { registerServiceWorker } from 'ShopUiBundle/modules/service-worker-utils';
import { loadTrbo } from 'ShopUiBundle/modules/trbo';
import { isTrboJsEnabled } from 'ShopUiBundle/modules/app/utils';

registerServiceWorker();
bootstrap();

if (isTrboJsEnabled()) {
   loadTrbo();
}

